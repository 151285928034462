import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PageProps } from 'gatsby';

import { LocaleNameSpaceKeys } from '../../../localization/translations';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import ContactCard from '../../../components/ContactCard/ContactCard';
import bdmContacts from '../../../data/broker-center/bdm-contacts';
import DownloadBlock, { DownloadLink } from '../../../components/DownloadBlock/DownloadBlock';
import PageMenu from '../../../components/PageMenu/PageMenu';
import brokerCentreMenu from '../../../data/page-menus/broker-centre';

import './style.scss';

type Props = PageProps & WithTranslation;

const MeetTheTeam: React.FC<Props> = (props) => {
  const { t } = props;
  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('meetTheTeam.seo.title'),
    },
    page: {
      title: t('meetTheTeam.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
        blockText: t('tagline'),
        shouldBeDigiBlock: true,
      },
    },
  };

  const getContactCards = () =>
    bdmContacts.map((card, i) => (
      <div key={`${card.name}-${i}`} className="column MeetTheTeam__contact-card">
        <ContactCard {...card} />
      </div>
    ));

  const brokerPackageDownLoadLinks: DownloadLink[] = [
    {
      to: `/downloads/general/HaventreeBank_BrokerPackage_2024-08-15.pdf`,
      label: t(`${LocaleNameSpaceKeys.COMMON}:english`),
    },
    {
      to: `/downloads/general/BanqueHaventree_troussepourlecourtiers_2024-08-15.pdf`,
      label: t(`${LocaleNameSpaceKeys.COMMON}:french`),
    },
  ];

  return (
    <Layout options={layoutOptions} className="MeetTheTeam">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <h2 className="h3">{t('meetTheTeam.dedicatedManagerBlock.heading')}</h2>
            <p>{t('meetTheTeam.dedicatedManagerBlock.description')}</p>

            <div className="MeetTheTeam__large-gap">{getContactCards()}</div>
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={brokerCentreMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.brokerResources`)}
            />

            <div className="MeetTheTeam__large-gap">
              <DownloadBlock
                heading={t(`${LocaleNameSpaceKeys.COMMON}:brokerPackage`)}
                downloadLinks={brokerPackageDownLoadLinks}
              />
            </div>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.BROKER_CENTRE)(MeetTheTeam);
